import React, { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { Blocks } from 'react-loader-spinner'
import NotificationAlert from "react-notification-alert";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "../assets/css/black-dashboard-react.css"
import { getItem, deleteItem, updateItem } from "../services/api.svs";
import { ThemeContext, themes } from "../contexts/ThemeContext"
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";
import { Auth } from 'aws-amplify';
import { DataGrid } from '@mui/x-data-grid';

function Users({ user }) {

  const { theme } = useContext(ThemeContext);

  const [IsLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([])
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseUpdate = () => setShowUpdate(false);
  // Update Form
  const [modalId, setId] = useState("");
  const [title, setTitle] = useState("");
  const [information, setInformation] = useState("");
  const [adminRewiew, setAdminReview] = useState("");
  const [datePublished, setDatePublished] = useState("");

  const notificationAlertRef = React.useRef(null);
  const notify = (place, message, type) => {

    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const editContactView = async (id) => {
    setIsLoaded(false)
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    let config = {
      headers: {
        'Authorization': `Bearer ${token}`

      }
    }
    await axios.get(`${process.env.REACT_APP_API_BASE}/admin/contact/${id}`, config).then(resp => {
      console.log(resp)
      console.log(resp.data.data.information)
      if (resp.data.data.length > 1) {
        let message = (<div>
          <div>
            Received multiple users matching search.
          </div>
        </div>);
        notify("tl", message, "danger");
      } else {
        setId(id)
        setDatePublished(resp.data.data[0].last_edit)
        setTitle(resp.data.data[0].title)
        setInformation(resp.data.data[0].information)
        setShowUpdate(true)
      }

      setIsLoaded(true)
    }).catch(resp => {
      setIsLoaded(true);
      let message = (<div>
        <div>
          There has been some issue.
        </div>
      </div>);
      notify("tl", message, "danger");
    })

  };

  const activeContact = async (id, data) => {
    setShowUpdate(false)
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    updateItem(`/admin/contact/${id}`, data, token).then(resp => {
      console.log("Update Resp", resp)
      setIsLoaded(true)
      window.location.reload()
      let message = (<div>
        <div>
          Processed contact
        </div>
      </div>)
      notify("tl", message, "success")
    }).catch(resp => {
      console.log(resp)
      setIsLoaded(true);
      let message = (<div>
        <div>
          There has been some issue.
        </div>
      </div>);
      notify("tl", message, "danger");
    })
  };

  const deleteContact = async (id) => {
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    deleteItem(`/admin/contact/${id}`, token).then(resp => {

      setIsLoaded(false)
      const newItemList = items.filter(
        (item) => item.contact_id !== id
      );
      setIsLoaded(true)
      setItems(newItemList);
      let message = (<div>
        <div>
          Deleted the contact
        </div>
      </div>)
      notify("tl", message, "danger")
    }).catch(resp => {
      console.log(resp)
      setIsLoaded(true)
    })
  };

  useEffect(() => {
    const getUsers = async () => {
      const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
      getItem('/admin/user/', token).then(resp => {
        console.log(resp.data)
        setItems(resp.data)
        setIsLoaded(true)
      }).catch(resp => {
        console.log(resp)
        setItems([])
        setIsLoaded(true)
        let message = (<div>
          <div>
            Error retrieved getting the data
          </div>
        </div>)
        notify("tl", message, "danger")
      })
      //stop loading when data is fetched
    }
    getUsers()
  }, [])

  const columnDefs = [
    { headerName: 'Email', field: 'email', flex: 1 },
    { headerName: 'Title', field: 'title', flex: 1 },
    { headerName: 'Type', field: 'is_type', flex: 1 },
    {
      headerName: 'Actions', flex: 1, renderCell: (params) => (
        <div>
          <a href="#" onClick={() => editContactView(params.row.contact_id)}><i className="tim-icons icon-pencil" /></a> &nbsp;
          {params.row.is_status !== "responded" ? (<a href="#" onClick={() => activeContact(params.row.contact_id, {})}><i className="tim-icons icon-check-2" /> &nbsp;</a>) : (null)}
          <a href="#" onClick={() => deleteContact(params.row.contact_id)}><i className="tim-icons icon-trash-simple" /></a>

        </div>
      ),
    }
  ];

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {IsLoaded ? (
        <>
          <>
            <div className="content">
              <Row>

                <Col md="12">
                  <Card className="card-plain">
                    <CardHeader>
                      <CardTitle tag="h4">Users</CardTitle>
                      <p className="category">Registered Users.</p>
                    </CardHeader>
                    <CardBody>
                      <DataGrid
                         sx={{
                          '& .MuiDataGrid-cell': {
                            color: theme === themes.dark ? 'white' : 'black',
                            fontWeight: 700,
                          },
                          '& .MuiDataGrid-columnHeader': {
                            color: theme === themes.dark ? 'black' : '#e5763bd9',
                            fontWeight: 700,
                          },
                        }}
                        rows={items}
                        columns={columnDefs}
                        pageSize={25}
                        rowsPerPageOptions={[25,50,75,100]}
                        getRowId={(row) => row.user_id}
                        loading={!IsLoaded}
                        autoHeight
                        checkboxSelection={false} // Change as needed
                        disableSelectionOnClick
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
            {/* Update modal */}
            <Modal show={showUpdate} onHide={handleClose} style={{ background: "rgba(20, 20, 20, 0.7)" }}>
              <Modal.Header sx={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}>
                <Modal.Title >Review Contact</Modal.Title>
              </Modal.Header>
              <Modal.Body style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}>
                <Form>
                  <Form.Group >
                    <Form.Label>ID</Form.Label>
                    <Form.Control style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}
                      type="id"
                      autoFocus
                      readOnly
                      value={modalId}
                    />
                  </Form.Group>
                  <Form.Group >
                    <Form.Label>Date Processed</Form.Label>
                    <Form.Control style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}
                      type="date_published"
                      autoFocus
                      value={datePublished}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group >
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}
                      type="title"
                      value={title}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={3} value={information} readOnly style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Admin Response</Form.Label>
                    <Form.Control as="textarea" rows={3} value={adminRewiew} onChange={(e) => setAdminReview(e.target.value)} style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }} />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}>
                <Button variant="secondary" onClick={handleCloseUpdate} style={{ marginLeft: "20%" }}>
                  Close
                </Button>
                <Button variant="primary" onClick={() => { activeContact({ "admin_response": adminRewiew }) }} style={{ marginRight: "20%" }}>
                  Save Changes
                </Button>
                <br />
                <br />
                <br />
                <br />
              </Modal.Footer>
            </Modal>
          </>



        </>)
        : (
          <div className="content">
            <Row>

              <Col md="12">
                <Card className="card-plain">
                  <CardHeader>
                    <CardTitle tag="h4">Users</CardTitle>
                    <p className="category">Messages shown to users when there is known issues.</p>
                  </CardHeader>
                  <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                  }}>
                    <Blocks
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>


        )
      }

    </>
  );
}

export default Users;
