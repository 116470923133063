import React, { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { Blocks } from 'react-loader-spinner'
import NotificationAlert from "react-notification-alert";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "../assets/css/black-dashboard-react.css"
import { getItem, deleteItem, postItem } from "../services/api.svs";
import * as Sentry from "@sentry/react";
import { ThemeContext, themes } from "../contexts/ThemeContext"

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";
import { Auth } from 'aws-amplify';
import { DataGrid } from '@mui/x-data-grid';

function OAuthProviders({ user }) {

  const { theme } = useContext(ThemeContext);

  const [IsLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([])
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseUpdate = () => setShowUpdate(false);
  // Update Form
  const [modalId, setId] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [datePublished, setDatePublished] = useState("");
  // Add form
  const [newTitle, setNewTitle] = useState("");
  const [newMessage, setNewMessage] = useState("");

  const notificationAlertRef = React.useRef(null);
  const notify = (place, message, type) => {
    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const editOAuthProviderView = async (id) => {
    setIsLoaded(false)
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    let config = {
      headers: {
        'Authorization': `Bearer ${token}`

      }
    }
    await axios.get(`${process.env.REACT_APP_API_BASE}/admin/audit/${id}`, config).then(resp => {
      console.log(resp)
      setId(id)
      setDatePublished(resp.data.data.date_published)
      setTitle(resp.data.data.title)
      setMessage(resp.data.data.message)
      setShowUpdate(true)
      setIsLoaded(true)
    }).catch(resp => {
      setIsLoaded(true);
      let message = (<div>
        <div>
          There has been some issue.
        </div>
      </div>);
      notify("tl", message, "danger");
    })

  };

  const addItem = async () => {

    let dataToSend = {
      "title": newTitle,
      "message": newMessage,
      "is_status": "created"
    }
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    postItem(`/admin/audit`, dataToSend, token).then(resp => {
      let newArray = [...items, resp.data.data];
      setItems(newArray)
      let message = (<div>
        <div>
          Added a new audit.
        </div>
      </div>);
      window.location.reload()
      notify("tl", message, "success");
      setShow(false)
    }).catch(resp => {
      Sentry.captureException(resp);
      let message = (<div>
        <div>
          Could not add new audit.
        </div>
      </div>);
      notify("tl", message, "danger");
      setShow(false)
    })

  };

  const activeOAuthProvider = async (id) => {
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    postItem(`/admin/audit/${id}/activate`, {}, token).then(resp => {

      setIsLoaded(true)
      window.location.reload()
      let message = (<div>
        <div>
          Activated audit
        </div>
      </div>)
      notify("tl", message, "success")
    }).catch(resp => {
      Sentry.captureException(resp);
      setIsLoaded(true);
      let message = (<div>
        <div>
          There has been some issue.
        </div>
      </div>);
      notify("tl", message, "danger");
    })
  };

  const deactiveOAuthProvider = async (id) => {
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    postItem(`/admin/audit/${id}/deactivate`, {}, token).then(resp => {
      setIsLoaded(true)
      window.location.reload()
      let message = (<div>
        <div>
          Deactivated audit
        </div>
      </div>)
      notify("tl", message, "success")
    }).catch(resp => {
      Sentry.captureException(resp);
      setIsLoaded(true);
      let message = (<div>
        <div>
          There has been some issue.
        </div>
      </div>);
      notify("tl", message, "danger");
    })
  };

  const deleteOAuthProvider = async (id) => {
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    deleteItem(`/admin/audit/${id}`, token).then(resp => {

      setIsLoaded(false)
      const newItemList = items.filter(
        (item) => item.pk !== id
      );
      setIsLoaded(true)
      setItems(newItemList);
      let message = (<div>
        <div>
          Deleted the audit
        </div>
      </div>)
      notify("tl", message, "danger")
    }).catch(resp => {
      console.log(resp)
      setIsLoaded(true)
    })
  };

  useEffect(() => {
    const getOAuthProviders = async () => {
      const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
      getItem('/admin/audit/?type=partial', token).then(resp => {
        console.log(resp)
        let auditData = resp.data.filter(data => data.sk !== "health")
        setItems(auditData)
        setIsLoaded(true)
      }).catch(resp => {
        console.log(resp)
        setItems([])
        setIsLoaded(true)
        let message = (<div>
          <div>
            Error retrieved getting the data
          </div>
        </div>)
        notify("tl", message, "danger")
      })
      //stop loading when data is fetched
    }
    getOAuthProviders()
  }, [])



  const columnDefs = [
    { headerName: 'Title', field: 'title', flex: 1 },
    { headerName: 'State', field: 'is_status', flex: 1 },
    {
      headerName: 'Actions', flex: 1, renderCell: (params) => (
        <div>
          <a href="#" onClick={() => editOAuthProviderView(params.row.pk)}><i className="tim-icons icon-pencil" /></a> &nbsp;
          <a href="#" onClick={() => deleteOAuthProvider(params.row.pk)}><i className="tim-icons icon-trash-simple" /></a>&nbsp;&nbsp;
          {params.row.is_status !== "published" ? (<a href="#" onClick={() => activeOAuthProvider(params.row.pk)}><i className="tim-icons icon-check-2" /></a>) : (<a href="#" onClick={() => deactiveOAuthProvider(params.row.pk)}><i className="tim-icons icon-simple-remove" /></a>)}
        </div>
      ),
    }
  ];



  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {IsLoaded ? (
        <>
          <>
            <div className="content">
              <Row>

                <Col md="12">
                  <Card className="card-plain">
                    <CardHeader>
                      <CardTitle tag="h4">OAuthProviders</CardTitle>
                      <p className="category">Messages shown to users when there is known issues. &nbsp;<a href="#" onClick={() => setShow(true)}><i className="tim-icons icon-simple-add" /></a></p>
                    </CardHeader>
                    <CardBody>
                      <DataGrid
                         sx={{
                          '& .MuiDataGrid-cell': {
                            color: theme === themes.dark ? 'white' : 'black',
                            fontWeight: 700,
                          },
                          '& .MuiDataGrid-columnHeader': {
                            color: theme === themes.dark ? 'black' : '#e5763bd9',
                            fontWeight: 700,
                          },
                        }}
                        rows={items}
                        columns={columnDefs}
                        pageSize={25}
                        rowsPerPageOptions={[25,50,75,100]}
                        getRowId={(row) => row.pk}
                        loading={!IsLoaded}
                        autoHeight
                        checkboxSelection={false} // Change as needed
                        disableSelectionOnClick
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>

            {/* Create modal */}
            <Modal show={show} onHide={handleClose} style={{ background: "rgba(20, 20, 20, 0.7)" }}>
              <Modal.Header sx={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}>
                <Modal.Title>Create OAuthProvider</Modal.Title>
              </Modal.Header>
              <Modal.Body style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}>
                <Form>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Title for the audit"
                      autoFocus
                      onChange={(e) => setNewTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="Message to show users" onChange={(e) => setNewMessage(e.target.value)} />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}>
                <Button variant="secondary" onClick={handleClose} style={{ marginLeft: "20%" }}>
                  Close
                </Button>
                <Button variant="primary" onClick={addItem} style={{ marginRight: "20%" }}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
            {/* Update modal */}
            <Modal show={showUpdate} onHide={handleClose} style={{ background: "rgba(20, 20, 20, 0.7)" }}>
              <Modal.Header>
                <Modal.Title>Update OAuthProvider</Modal.Title>
              </Modal.Header>
              <Modal.Body style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}>
                <Form>
                  <Form.Group >
                    <Form.Label>ID</Form.Label>
                    <Form.Control
                      type="id"
                      autoFocus
                      readOnly
                      value={modalId}
                    />
                  </Form.Group>
                  <Form.Group >
                    <Form.Label>Last published</Form.Label>
                    <Form.Control
                      type="date_published"
                      autoFocus
                      value={datePublished}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group >
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      autoFocus
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={3} value={message} onChange={(e) => setMessage(e.target.value)} />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}>
                <Button variant="secondary" onClick={handleCloseUpdate} style={{ marginLeft: "20%" }}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleCloseUpdate} style={{ marginRight: "20%" }}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </>



        </>)
        : (
          <div className="content">
            <Row>

              <Col md="12">
                <Card className="card-plain">
                  <CardHeader>
                    <CardTitle tag="h4">OAuthProviders</CardTitle>
                    <p className="category">Messages shown to users when there is known issues.</p>
                  </CardHeader>
                  <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                  }}>
                    <Blocks
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>


        )
      }

    </>
  );
}

export default OAuthProviders;
