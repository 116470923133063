import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import AdminLayout from "layouts/Admin/Admin.js";
import NonAdminLayout from "layouts/NonAdmin/NonAdmin.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";


import { ThreeCircles } from 'react-loader-spinner'
import '@aws-amplify/ui-react/styles.css';
import "assets/css/auth.css"
import { Auth } from 'aws-amplify';


export function GisticsApp() {
  const [isPresentResult, setIsPresentResult] = useState(false);
  const [IsLoaded, setIsLoaded] = useState(false);


  useEffect(() => {
    Auth.currentAuthenticatedUser().then(response => {
      console.log(response.signInUserSession)
      if (response.signInUserSession.idToken.payload["super_admin"].includes("true")) {
        setIsPresentResult(true)
      } else {
        setIsPresentResult(false)
      }
    }
    ).catch(response =>
      setIsPresentResult(false)
    )

    setIsLoaded(true)
  }, [])

  return (<>

    {IsLoaded ? (
      <>
        {isPresentResult ? (
          <>
            <ThemeContextWrapper>
              <BackgroundColorWrapper>
                <BrowserRouter>
                  <Switch>
                    <Route path="/" render={(props) => <AdminLayout {...props} />} />
                  </Switch>
                </BrowserRouter>
              </BackgroundColorWrapper>
            </ThemeContextWrapper>
          </>)
          : (
            <ThemeContextWrapper>
              <BackgroundColorWrapper>
                <BrowserRouter>
                  <Switch>
                    <Route path="/" render={(props) => <NonAdminLayout {...props} />} />
                  </Switch>
                </BrowserRouter>
              </BackgroundColorWrapper>
            </ThemeContextWrapper>
          )
        }

      </>)
      : (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}>
          <ThreeCircles
            height="100"
            width="100"
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor="#FFDD00"
            innerCircleColor="#000000"
            middleCircleColor="#737373"
          /></div>

      )
    }
  </>

  );
}

