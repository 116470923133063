import React, { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { Blocks } from 'react-loader-spinner'
import NotificationAlert from "react-notification-alert";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "../assets/css/black-dashboard-react.css"
import { getItem, postItem, updateItem, deleteItem } from "../services/api.svs";
import { Link } from "react-router-dom"
import { ThemeContext, themes } from "../contexts/ThemeContext"
import { DataGrid } from '@mui/x-data-grid';
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";
import { Auth } from 'aws-amplify';
import BusinessOverview from './BusinessOverview';

function LicenseComponents({ user }) {

  const theme = useContext(ThemeContext);

  const [IsLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([])
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseUpdate = () => setShowUpdate(false);
  // Modals
  const [showUpdate, setShowUpdate] = useState(false);

  // Update Form
  const [dateCreated, setDateCreated] = useState("");
  const [businessID, setBusinessID] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessType, setBusinessType] = useState("");

  // Add form
  const [newType, setNewType] = useState("");
  const [newBusinessName, setNewBusinessName] = useState("");
  const [newBusinessCRN, setNewBusinessCRN] = useState("");
  const [newBusinessEmail, setNewBusinessEmail] = useState("");
  const [newBusinessNumber, setNewBusinessNumber] = useState("");
  // Transporter additional
  const [esEngine, setESEngine] = useState("");
  const [esURL, setESURL] = useState("");
  const [esSecretARN, setESSecretARN] = useState("");
  const [s3Bucket, setS3Bucket] = useState("");

  const notificationAlertRef = React.useRef(null);
  const notify = (place, message, type) => {

    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const updateBusiness = async () => {
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
    let dataToSend = {
      "is_type": businessType,
      "business_name": businessName,
      "business_id": businessID
    }
    updateItem(`/admin/Component`, dataToSend, token).then(resp => {
      console.log(resp)
      let message = (<div>
        <div>
          Updated a new Component.
        </div>
      </div>);
      notify("tl", message, "success");
      setShow(false)
    }).catch(() => {
      let message = (<div>
        <div>
          Could not add update Component.
        </div>
      </div>);
      notify("tl", message, "danger");
      setShow(false)
    })
    setNewBusinessName("")
    setNewType("")
  }

  const editBusinessView = async (id, business_name) => {
    setIsLoaded(false)
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    await axios.get(`${process.env.REACT_APP_API_BASE}/admin/Component/${id}?Component=${business_name}`, config).then(resp => {
      console.log(resp)
      setDateCreated(resp.data.data.date_created)
      setBusinessID(resp.data.data.business_id)
      setBusinessName(resp.data.data.business_name)
      setBusinessType(resp.data.data.is_type)
      setShowUpdate(true)
      setIsLoaded(true)
    }).catch(resp => {
      setIsLoaded(true);
      let message = (<div>
        <div>
          There has been some issue.
        </div>
      </div>);
      notify("tl", message, "danger");
    })
  };

  const addItem = async (type) => {

    let dataToSend = {}

    if (type === "warehouse") {
      dataToSend = {
        "is_type": newType,
        "business_name": newBusinessName,
        "crn": newBusinessCRN,
        "contact_number": newBusinessNumber,
        "email": newBusinessEmail,

      }
    } else {
      dataToSend = {
        "is_type": newType,
        "business_name": newBusinessName,
        "crn": newBusinessCRN,
        "contact_number": newBusinessNumber,
        "email": newBusinessEmail,
        "setting": {
          "es_engine": esEngine,
          "es_url": esURL,
          "es_secret_arn": esSecretARN,
          "s3_bucket": s3Bucket,
        }
      }
    }

    console.log(dataToSend)
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    postItem(`/admin/Component`, dataToSend, token).then(resp => {
      let newArray = [...items, resp.data.data];
      setItems(newArray)
      let message = (<div>
        <div>
          Added a new Component.
        </div>
      </div>);
      notify("tl", message, "success");
      setShow(false)
    }).catch(() => {
      let message = (<div>
        <div>
          Could not add new Component.
        </div>
      </div>);
      notify("tl", message, "danger");
      setShow(false)
    })
    setNewBusinessName("")
    setNewType("")

  };

  const deactiveBusiness = async (id, business_name) => {
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    deleteItem(`/admin/Component/${id}?Component=${business_name}`, token).then(resp => {
      console.log("Delete", resp)
      const newItemList = items.filter(
        (item) => item.business_id !== id
      );
      setItems(newItemList);
      setIsLoaded(true)
      let message = (<div>
        <div>
          Deleted Component
        </div>
      </div>)
      notify("tl", message, "success")
    }).catch(resp => {
      setIsLoaded(true);
      let message = (<div>
        <div>
          There has been some issue.
        </div>
      </div>);
      notify("tl", message, "danger");
    })
  };

  const openBusiness = (id) => {
    return (<BusinessOverview />);
  };

  useEffect(() => {
    const getBusinesss = async () => {
      const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
      getItem('/admin/components/?type=partial', token).then(resp => {
        setItems(resp.data)
        setIsLoaded(true)
      }).catch(resp => {
        setItems([])
        setIsLoaded(true)
        let message = (<div>
          <div>
            Error retrieved getting the data
          </div>
        </div>)
        notify("tl", message, "danger")
      })
      //stop loading when data is fetched
    }
    getBusinesss()
  }, [])

  const columnDefs = [
    { headerName: 'Name', field: 'component_name', flex: 1 },
    { headerName: 'Type', field: 'is_type', flex: 1 },
    {
      headerName: 'Actions', flex: 1, renderCell: (params) => (
        <div>
          <a href="#" onClick={() => editBusinessView(params.row.business_id, params.row.business_name)}><i className="tim-icons icon-pencil" /></a> &nbsp;
          <Link to={{
            pathname: '/overview',
            // search: '?',
            state: params.row
          }}>
            <a href="#"><i className="tim-icons icon-user-run" /></a>&nbsp;&nbsp; </Link>
          {params.row.is_status !== "published" ? (<a href="#" onClick={() => deactiveBusiness(params.row.business_id, params.row.business_name)}><i className="tim-icons icon-trash-simple" /></a>) : (null)}

        </div>
      ),
    }
  ];


  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {IsLoaded ? (
        <>
          <>
            <div className="content">
              <Row>
                <Col md="12">
                  <Card className="card-plain">
                    <CardHeader>
                      <CardTitle tag="h4">Component</CardTitle>
                      <p className="category">Service components. &nbsp;<a href="#" onClick={() => setShow(true)}><i className="tim-icons icon-simple-add" /></a></p>
                    </CardHeader>
                    <CardBody>
                      <DataGrid
                         sx={{
                          '& .MuiDataGrid-cell': {
                            color: theme === themes.dark ? 'white' : 'black',
                            fontWeight: 700,
                          },
                          '& .MuiDataGrid-columnHeader': {
                            color: theme === themes.dark ? 'black' : '#e5763bd9',
                            fontWeight: 700,
                          },
                        }}
                        rows={items}
                        columns={columnDefs}
                        pageSize={25}
                        rowsPerPageOptions={[25,50,75,100]}
                        getRowId={(row) => row.component_id}
                        loading={!IsLoaded}
                        autoHeight
                        checkboxSelection={false} // Change as needed
                        disableSelectionOnClick
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>

            {/* Create modal */}
            <Modal show={show} onHide={handleClose} style={{ background: "rgba(20, 20, 20, 0.7)" }}>
              <Modal.Header closeButton style={(theme.theme !== 'white-content') ? {} : { backgroundColor: "white" }}>
                <Modal.Title>Create Component</Modal.Title>
              </Modal.Header>
              <Modal.Body style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}>
                <Form>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Component Name</Form.Label>
                    <Form.Control
                      type="email"
                      value={newBusinessName}
                      autoFocus
                      onChange={(e) => setNewBusinessName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="description"
                      value={newBusinessCRN}
                      autoFocus
                      onChange={(e) => setNewBusinessCRN(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>APIPaths</Form.Label>
                    <Form.Control
                      type="email"
                      value={newBusinessEmail}
                      autoFocus
                      onChange={(e) => setNewBusinessEmail(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Frontend Paths</Form.Label>
                    <Form.Control
                      type="email"
                      value={newBusinessNumber}
                      autoFocus
                      onChange={(e) => setNewBusinessNumber(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicSelect">
                    <Form.Label>Select Norm Type</Form.Label>
                    <Form.Control
                      as="select"
                      value={newType}
                      onChange={e => {
                        setNewType(e.target.value);
                      }}

                    >
                      <option value="" defaultValue></option>
                      <option value="warehouse" defaultValue>Unrestricted</option>
                      <option value="transporter">Limit</option>
                    </Form.Control>
                  </Form.Group>
                  {newType === "transporter" && (
                    <>
                      <br />
                      <Form.Label>Limit Settings</Form.Label>
                      <br />
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Limit total</Form.Label>
                        <Form.Control
                          type="esEngine"
                          value={esEngine}
                          autoFocus
                          onChange={(e) => setESEngine(e.target.value)}
                        />
                      </Form.Group>
                    
                    </>
                  )}
                </Form>
              </Modal.Body>
              <Modal.Footer style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}>
                <Button variant="secondary" onClick={handleClose} style={{ marginLeft: "20%" }}>
                  Close
                </Button>
                <Button variant="primary" onClick={addItem} style={{ marginRight: "20%" }}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
            {/* Update modal */}
            <Modal show={showUpdate} onHide={handleClose} style={{ background: "rgba(20, 20, 20, 0.7)" }}>
              <Modal.Header>
                <Modal.Title>Partial Update Component</Modal.Title>
              </Modal.Header>
              <Modal.Body style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}>
                <Form>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Component ID</Form.Label>
                    <Form.Control
                      type="id"
                      value={businessID}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Creation Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={dateCreated}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Component Name</Form.Label>
                    <Form.Control
                      type="email"
                      value={businessName}
                      onChange={(e) => setBusinessName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicSelect">
                    <Form.Label>Select Norm Type</Form.Label>
                    <Form.Control
                      as="select"
                      value={businessType}
                      onChange={e => {
                        setBusinessType(e.target.value);
                      }}

                    >
                      {businessType == "warehouse" ? (
                        <>
                          <option value="warehouse" defaultValue>Warehouse</option>
                          <option value="transporter" >Transporter</option>
                        </>
                      ) : (
                        <>
                          <option value="warehouse"  >Warehouse</option>
                          <option value="transporter" defaultValue>Transporter</option>
                        </>
                      )

                      }

                    </Form.Control>
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}>
                <Button variant="secondary" onClick={handleCloseUpdate} style={{ marginLeft: "20%" }}>
                  Close
                </Button>
                <Button variant="primary" onClick={updateBusiness} style={{ marginLeft: "20%" }}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </>



        </>)
        : (
          <div className="content">
            <Row>

              <Col md="12">
                <Card className="card-plain">
                  <CardHeader>
                    <CardTitle tag="h4">Component</CardTitle>
                    <p className="category">Registered businesses.</p>
                  </CardHeader>
                  <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                  }}>
                    <Blocks
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>


        )
      }

    </>
  );
}

export default LicenseComponents;
