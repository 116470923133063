import Logo from './assets/img/gistics-full.png';

export let AmplifyComponent = {
  Header() {

    return (
      <div style={{ width: "100%" }}>

        <img className="companyLogo" src={Logo} />

        <br />
      </div>
    );
  },

  Footer() {

    return (
      <>
        <br />
        <div style={{ width: "100%", bottom: '0px' }}>
          @Pyrrho Tech 2023
        </div>
      </>
    );
  },
}