
import React, { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { Blocks } from 'react-loader-spinner'
import NotificationAlert from "react-notification-alert";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import { Auth } from 'aws-amplify';
import { updateItem } from 'services/api.svs';
import { ThemeContext } from "../contexts/ThemeContext"
import { BackgroundColorContext } from "../contexts/BackgroundColorContext"
import { getItem } from 'services/api.svs';

function Health({ user }) {
  const [IsLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState("")
  const [radioValue, setRadioValue] = useState(1);

  const theme = useContext(ThemeContext);
  const bgColour = useContext(BackgroundColorContext);

  const notificationAlertRef = React.useRef(null);
  const notify = (place, message, type) => {
    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const radios = [
    { name: 'Up', value: 1 },
    { name: 'Down', value: 2 },
    { name: 'Maintainance', value: 3 },
  ];

  useEffect(() => {
    const getItems = async () => {
      const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
      
      await getItem(`/health/`, token).then(resp => {
        setItems(resp.data)
        let health_status = resp.data.is_status
        if (health_status === "UP") {
          setRadioValue(1)
        } else if (health_status === "DOWN") {
          setRadioValue(2)
        }
        else if (health_status === "MAINTAINANCE") {
          setRadioValue(3)
        } else {
          // Alert
          let message = (<div>
            <div>
              Unknkown status.
            </div>
          </div>);
          notify("tl", message, "danger");
        }
        setIsLoaded(true)
      }).catch(resp => {
        let message = (<div>
          <div>
            Unknkown status.
          </div>
        </div>);
        notify("tl", message, "danger");
        setItems("")
        setIsLoaded(true)
      })
      //stop loading when data is fetched
    }
    getItems()

  }, [])

  const updateHealth = async (is_status) => {
    setIsLoaded(false)
    let dataToSend = {
      is_status: is_status
    }
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    updateItem(`/admin/health/`, dataToSend, token).then(resp => {
      console.log(resp.data.data)
      let message = (<div>
        <div>
          Health updated.
        </div>
      </div>);
      notify("tl", message, "success");
      setIsLoaded(true)
    }).catch(resp => {
      let message = (<div>
        <div>
          Could not set Health.
        </div>
      </div>);
      notify("tl", message, "danger");
      setIsLoaded(true)
    })
  }

  const changeHealth = async (e) => {
    if (e === "1") {
      await updateHealth("UP").then(
        setRadioValue(1)
      )

    } else if (e === "2") {
      await updateHealth("DOWN").then(
        setRadioValue(2)
      )
    }
    else if (e === "3") {
      await updateHealth("MAINTAINANCE").then(() => {
        setRadioValue(3)
      }

      )
    } else {
      let message = (<div>
        <div>
          Unknown object passed.
        </div>
      </div>);
      notify("tl", message, "danger");
    }
  }

  return (

    <>
      <NotificationAlert ref={notificationAlertRef} />
      {IsLoaded ? (
        <>

          <>
            <div className="content">
              <Row>

                <Col md="12">
                  <Card className="card-plain">
                    <CardHeader>
                      <CardTitle tag="h4">Health</CardTitle>
                      <p className="category">Current system health: {items === "" && (
                        <p style={{ fontSize: "20px" }}>No state in db, creating health one</p>
                      )

                      }</p>
                    </CardHeader>
                    <CardBody>
                      <ToggleButtonGroup
                        value={radioValue}
                        exclusive
                        onChange={(e) => changeHealth(e.currentTarget.value)}
                        style={{ color: "white", width: "100%", justifyContent: "center" }}
                      >
                        {radios.map((radio, idx) => (
                          <ToggleButton value={radio.value}
                            key={idx}
                            id={`radio-${idx}`}
                            checked={radioValue === radio.value}
                            style={(theme.theme !== 'white-content') ?
                              (radioValue === radio.value) ?
                                { color: "white", borderColor: bgColour.color, borderWidth: "5px", width: "10vw", margin: "20px" }
                                : { color: "white", borderColor: "white", width: "10vw", margin: "20px" }
                              : (radioValue === radio.value) ?
                                { color: "black", borderColor: bgColour.color, borderWidth: "5px", width: "10vw", margin: "20px" }
                                : { color: "black", borderColor: "black", width: "10vw", margin: "20px" }}>
                            {radio.name}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </>

        </>)
        : (
          <div className="content">
            <Row>

              <Col md="12">
                <Card className="card-plain">
                  <CardHeader>
                    <CardTitle tag="h4">Health</CardTitle>
                  </CardHeader>
                  <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                  }}>
                    <Blocks
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>


        )
      }

    </>
  );
}

export default Health;
