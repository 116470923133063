import axios from "axios";

export const postItem = async (path, dataToSend, token) => {
  let config = {
    headers: {
      'Access-Control-Allow-Origin' : '*',
      'Authorization': `Bearer ${token}`
    }
  }
  return axios.post(`${process.env.REACT_APP_API_BASE}${path}`, dataToSend, config)
}

export const getItem = (path, token) => {
  let config = {
    headers: {
      'Access-Control-Allow-Origin' : '*',
      'Authorization': `Bearer ${token}`
    }
  }
  return axios.get(`${process.env.REACT_APP_API_BASE}${path}`, config)
}


export const updateItem = (path, dataToSend, token) => {
  let config = {
    headers: {
      'Access-Control-Allow-Origin' : '*',
      'Authorization': `Bearer ${token}`
    }
  }
  return axios.put(`${process.env.REACT_APP_API_BASE}${path}`, dataToSend, config)

}

export const deleteItem = (path, token) => {
  let config = {
    headers: {
      'Access-Control-Allow-Origin' : '*',
      'Authorization': `Bearer ${token}`
    }
  }
  return axios.delete(`${process.env.REACT_APP_API_BASE}${path}`, config)

}
