

import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from "axios";
import { Blocks } from 'react-loader-spinner'
import NotificationAlert from "react-notification-alert";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import "../assets/css/black-dashboard-react.css"
import { getItem, deleteItem, postItem, updateItem } from "../services/api.svs";
import Button from 'react-bootstrap/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import * as Sentry from "@sentry/react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";
import Grid from '@mui/material/Grid';
import { Auth } from 'aws-amplify';
import { ThemeContext } from "../contexts/ThemeContext"
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import Geocode from "react-geocode";
Geocode.setApiKey(process.env.REACT_APP_GOOGLE_TOKEN);
Geocode.setLocationType("ROOFTOP");

// Enable or disable logs. Its optional.
Geocode.enableDebug();

function BusinessOverview(props) {
  const theme = useContext(ThemeContext);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_TOKEN
  })

  const [loaded, setLoaded] = useState(false);
  const [settings, setSettings] = useState(false)
  const [settingTV, setTVSetting] = useState({})
  const [items, setItems] = useState([])
  const [users, setUsers] = useState([])
  const [transporters, setTransporters] = useState([])
  const [transporterViewers, setTransporterViewers] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseInvite = () => setShowInvite(false);
  const handleCloseDependent = () => setShowDependent(false);
  const handleCloseViewer = () => setshowTViewwer(false);
  // Invite
  const [alignment, setAlignment] = React.useState('viewer');
  const [email, setEmail] = useState("");
  const [showInvite, setShowInvite] = useState(false);
  // Setting forms
  const [showSettingsSave, setShowSettingsSave] = useState(false);
  const [showSettingsTSave, setShowSettingTSave] = useState(false);
  // Transporter
  const [transporterType, setTransporterType] = useState("");
  const [esEngine, setESEngine] = useState("");
  const [esUrl, setESURL] = useState("");
  const [secretARN, setSecretARN] = useState("");
  const [s3Bucket, setS3Bucket] = useState("");

  // Warehouse
  const [warehouseType, setWarehouseType] = useState("");
  // Add Deps
  const [showDependent, setShowDependent] = useState(false);
  // ViewerForm
  const [showTViewer, setshowTViewwer] = useState(false);
  // Warehouse formA
  const [warehouseName, setWarehouseName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [location, setLocation] = useState("");
  const [operationEmail, setOperationEmail] = useState("");
  const [emailEscalation2, setEmailEscalation2] = useState("");
  const [operatingHours, setOperatingHours] = useState("");
  // Transporter forms
  const [transporterName, setTransporterName] = useState("");
  const [tContactNumber, setTContactNumber] = useState("");
  // ViewerForm
  const [viewerName, setViewerName] = useState("");
  const [viewerIdentitfier, setIdentityfier] = useState("");
  const [viewerContact, setViewerContact] = useState("");
  const [viewerEmail, setVieweEmail] = useState("");
  // Map
  const [map, setMap] = useState(null)
  const [showMarker, setShowMarker] = useState(false)
  const [markerLong, setMarkerLong] = useState(0)
  const [markerLat, setMarkerLat] = useState(0)
  const [markers, setMarkers] = useState([])
  const [center, setCenter] = useState({
    lat: -25.9991795,
    lng: 28.1262927
  })



  const containerStyle = {
    width: '100%',
    minHeight: '80%'
  };
  const exampleMapStyles = [
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
  ];

  const notificationAlertRef = React.useRef(null);
  const notify = (place, message, type) => {

    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const updateUser = async () => {
    setShowInvite(true)

  };

  const removeTransporter = async (id) => {
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    deleteItem(`/admin/transporter/${props.location.state.business_id}/${id}?business=${props.location.state.business_name}`, token).then(resp => {
      let message = (<div>
        <div>
          Removed Transporter.
        </div>
      </div>);
      notify("tl", message, "success");
    }).catch(resp => {
      Sentry.captureException(resp);
      let message = (<div>
        <div>
          Could not remove the Transporter.
        </div>
      </div>);
      notify("tl", message, "danger");

    })
  };

  const removeWarehouse = async (id) => {
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    deleteItem(`/admin/warehouse/${props.location.state.business_id}/${id}?business=${props.location.state.business_name}`, token).then(resp => {
      let message = (<div>
        <div>
          Removed Warehouse.
        </div>
      </div>);
      notify("tl", message, "success");
    }).catch(resp => {
      Sentry.captureException(resp);
      let message = (<div>
        <div>
          Could not remove the Warehouse.
        </div>
      </div>);
      notify("tl", message, "danger");

    })
  };


  const removeUser = async (email) => {
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    deleteItem(`/admin/user/${props.location.state.business_id}/${email}?business_name=${props.location.state.business_name}`, {}, token).then(resp => {
      let message = (<div>
        <div>
          Removed User.
        </div>
      </div>);
      notify("tl", message, "success");
    }).catch(resp => {
      Sentry.captureException(resp);
      let message = (<div>
        <div>
          Could not remove the user.
        </div>
      </div>);
      notify("tl", message, "danger");
    })
  }

  const sendInvite = async () => {
    let dataToSend = {
      "email": email,
      "group": alignment,
    }
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    postItem(`/admin/user/${props.location.state.business_id}?business_name=${props.location.state.business_name}`, dataToSend, token).then(resp => {
      let newArray = [...items, resp.data];
      setUsers(newArray)
      let message = (<div>
        <div>
          Added user.
        </div>
      </div>);
      notify("tl", message, "success");
      setShowInvite(false)
    }).catch(resp => {
      let message = (<div>
        <div>
          Could not add new user.
        </div>
      </div>);
      notify("tl", message, "danger");
      setShowInvite(false)
    })
  }

  const addTransporter = async () => {
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    const type = "Transporter"
    let dataToSend = {
      "name": transporterName,
      "contact_number": tContactNumber
    }
    postItem(`/admin/transporter/${props.location.state.business_id}?business=${props.location.state.business_name}`, dataToSend, token).then(resp => {
      let message = (<div>
        <div>
          Added a new {type}.
        </div>
      </div>);
      notify("tl", message, "success");
      setShowDependent(false)
      window.location.reload()
    }).catch(resp => {
      Sentry.captureException(resp);
      let message = (<div>
        <div>
          Could not add new {type}.
        </div>
      </div>);
      notify("tl", message, "danger");
      setShow(false)
    })


  };

  const addWarehouse = async () => {
    let dataToSend = {
      "name": warehouseName,
      "contact_number": contactNumber,
      "location": location,
      "email_escalation1": operationEmail,
      "email_escalation2": emailEscalation2,
      "operating_hours": operatingHours,
    }
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()

    const type = "Warehouse"
    postItem(`/admin/warehouse/${props.location.state.business_id}?business=${props.location.state.business_name}`, dataToSend, token).then(resp => {

      let message = (<div>
        <div>
          Added a new {type}.
        </div>
      </div>);
      notify("tl", message, "success");
      setShowDependent(false)
      window.location.reload()
    }).catch(resp => {
      Sentry.captureException(resp);
      let message = (<div>
        <div>
          Could not add new {type}.
        </div>
      </div>);
      notify("tl", message, "danger");
      setShowDependent(false)
    })

  };

  const addViewer = async () => {
    let dataToSend = {
      "business_name": viewerName,
      "contact_number": viewerContact,
      "crn": viewerIdentitfier,
      "email": viewerEmail,
    }
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()

    const type = "Viewer"
    postItem(`/admin/transporter/${props.location.state.business_id}/onboard?business=${props.location.state.business_name}`, dataToSend, token).then(resp => {

      let message = (<div>
        <div>
          Added a new {type}.
        </div>
      </div>);
      notify("tl", message, "success");
      setShowDependent(false)
      window.location.reload()
    }).catch(resp => {
      Sentry.captureException(resp);
      let message = (<div>
        <div>
          Could not add new {type}.
        </div>
      </div>);
      notify("tl", message, "danger");
      setShowDependent(false)
    })

  };

  const setTransporterSettings = async () => {
    let dataToSend = {
      es_engine: esEngine,
      es_secret_arn: secretARN,
      es_url: esUrl,
      is_type: transporterType,
      s3_bucket: s3Bucket
    }
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    setLoaded(false)
    updateItem(`/admin/setting/${props.location.state.business_id}`, dataToSend, token).then(resp => {
      setLoaded(true)
      let message = (<div>
        <div>
          Updated the business
        </div>
      </div>)
      notify("tl", message, "success")
    }).catch(resp => {
      Sentry.captureException(resp)
      setLoaded(true)
    })
  }

  const removeDeps = async (id) => {
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    deleteItem(`/business/${id}`, token).then(resp => {

      setLoaded(false)
      const newItemList = items.filter(
        (item) => item.pk !== id
      );
      setLoaded(true)
      setItems(newItemList);
      let message = (<div>
        <div>
          Deleted the business
        </div>
      </div>)
      notify("tl", message, "danger")
    }).catch(resp => {
      Sentry.captureException(resp)
      setLoaded(true)
    })
  };

  const onClick = (e) => {
    Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
      (response) => {
        setLocation(response.results[0].formatted_address)
        setShowDependent(true)
      }
    );
    console.log("Add marker")
    console.log(e.latLng.lng(), ":", e.latLng.lat())
    setMarkerLong(e.latLng.lng())
    setMarkerLat(e.latLng.lat())
    setShowMarker(true)
  }

  useEffect(() => {
    let message = (<div>
      <div>
        Error getting the data
      </div>
    </div>)

    const getBusinesss = async () => {
      const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
      getItem(`/admin/business/${props.location.state.business_id}`, token).then(businessResp => {

        getItem(`/admin/setting/${props.location.state.business_id}`, token).then(resp => {


          if (props.location.state.is_type === 'warehouse') {
            console.log("Settings:", resp.data.setting)
          } else {
            console.log(resp.data)
            setTransporterType(businessResp.data.setting.is_type)
            setESEngine(businessResp.data.setting.es_engine)
            setESURL(businessResp.data.setting.es_url)
            setSecretARN(businessResp.data.setting.es_secret_arn)
            setS3Bucket(businessResp.data.setting.s3_bucket)
          }
          setTVSetting(resp.data.setting)
          setSettings(true)

          getItem(`/admin/user/${props.location.state.business_id}?business=${props.location.state.business_name}`, token).then(resp => {
            console.log("Users: ", resp.data.linked_users)
            // setUsers(resp.data.linked_users)
            if (props.location.state.is_type === 'warehouse') {
              getItem(`/admin/warehouse/${props.location.state.business_id}?business=${props.location.state.business_name}`, token).then(resp => {
                setWarehouses(resp.data.warehouses)
                // Get Warehouse markers
                if (Array.isArray(Object.keys(resp.data.warehouses))) {
                  let index = 1
                  const warehouseResp = resp.data.warehouses
                  // Loop over
                  Object.keys(warehouseResp).map((key) => {
                    Geocode.fromAddress(warehouseResp[key].location).then(
                      (response) => {
                        const { lat, lng } = response.results[0].geometry.location;
                        if (index === 1) {
                          setCenter({
                            lat: lat,
                            lng: lng
                          })
                        }
                        var newArray = markers;
                        console.log("Adding", lng, lat)
                        newArray.push({
                          "lng": lng,
                          "lat": lat
                        });
                        setMarkers(newArray)
                        setShowMarker(true)
                        if (index === Object.keys(warehouseResp).length) {
                          setLoaded(true)
                        }
                        index++;

                      },
                      (error) => {
                        if (index === Object.keys(warehouses).length) {
                          setLoaded(true)
                        }
                        index++;
                      }
                    ).catch(error => {
                      Sentry.captureException(error)
                      let message = (<div>
                        <div>
                          {warehouseResp[key].location} for warehouse {warehouseResp[key].name} doesnt seem to be valid
                        </div>
                      </div>)
                      notify("tl", message, "danger")
                    }

                    );

                  })
                }
                setLoaded(true)

              }).catch(resp => {
                console.log(resp)
                Sentry.captureException(resp);
                setWarehouses([])

                notify("tl", message, "danger")
              })
            } else if (props.location.state.is_type === 'transporter') {
              // getItem(`/admin/transporter/${props.location.state.business_id}?business=${props.location.state.business_name}`, token).then(resp => {
              //   setTransporters(resp.data.transporters)
              //   getItem(`/admin/transporter/viewer/${props.location.state.business_id}`, token).then(resp => {
              //     console.log("Viewwers", resp.data.linked_viewers)
              //     setTransporterViewers(resp.data.linked_viewers)
              //     setLoaded(true)
              //   }).catch(resp => {
              //     console.log(resp)
              //     Sentry.captureException(resp);
              //     setTransporterViewers([])
              //     setLoaded(true)
              //     notify("tl", message, "danger")
              //   })
              //   setLoaded(true)
              // }).catch(resp => {
              //   Sentry.captureException(resp);
              //   setTransporters([])
              //   setLoaded(true)
              //   notify("tl", message, "danger")
              // })
              setLoaded(true)
            } else {
              setLoaded(true)
            }

          }).catch(resp => {
            console.log(resp)
            Sentry.captureException(resp);
            setUsers([])
            notify("tl", message, "danger")
          })
        }).catch(resp => {
          console.log(resp)
          Sentry.captureException(resp);
          setSettings(false)

          notify("tl", message, "danger")
        })
      }).catch(resp => {
        console.log(resp)
        Sentry.captureException(resp);
        setItems([])

        notify("tl", message, "danger")
      })

    }
    getBusinesss()
  }, [])

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    map.setZoom(14)
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {loaded ? (
        <>
          <div className="content">
            {settings ? (
              <>

                <Row>
                  <Col md="12">
                    <Card className="card-plain">
                      <CardHeader>
                        <CardTitle tag="h4">Business Settings</CardTitle>
                        <p className="category">Settings linked. &nbsp;</p>
                      </CardHeader>
                      <CardBody>

                        <Form>
                          {
                            props.location.state.is_type === 'warehouse' ? (<>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Warehouse Type</Form.Label>
                                <Form.Control
                                  type="type"
                                  value={warehouseType}
                                  placeholder="Business Type"
                                  autoFocus
                                  onChange={(e) => { setWarehouseType(e.target.value); setShowSettingsSave(true) }}

                                />
                                {showSettingsSave && (<>
                                  <Button variant="secondary" onClick={handleCloseInvite} >
                                    Close
                                  </Button>
                                  <Button variant="primary" onClick={sendInvite}>
                                    Save Changes
                                  </Button>
                                </>)}

                              </Form.Group>

                            </>) : (<>
                              {props.location.state.is_type === 'transporter' ? (
                                <Row>
                                  <Col lg={4} xs={12}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      <Form.Label >Transporter Type</Form.Label>
                                      <Form.Control
                                        type="type"
                                        value={transporterType}
                                        placeholder="Business Type"
                                        onChange={(e) => { setTransporterType(e.target.value); setShowSettingTSave(true) }}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={4} xs={12}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      <Form.Label>ES Engine</Form.Label>
                                      <Form.Control
                                        type="es_engine"
                                        value={esEngine}
                                        placeholder="Engine"

                                        onChange={(e) => { setESEngine(e.target.value); setShowSettingTSave(true) }}

                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={4} xs={12}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      <Form.Label>ES URL</Form.Label>
                                      <Form.Control
                                        type="es_url"
                                        value={esUrl}
                                        placeholder="Business Type"

                                        onChange={(e) => { setESURL(e.target.value); setShowSettingTSave(true) }}

                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={4} xs={12}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      <Form.Label>Secret ARN</Form.Label>
                                      <Form.Control
                                        type="es_secret_arn"
                                        value={secretARN}
                                        placeholder="ARN of secret "

                                        onChange={(e) => { setSecretARN(e.target.value); setShowSettingTSave(true) }}

                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={4} xs={12}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      <Form.Label>S3 Bucket</Form.Label>
                                      <Form.Control
                                        type="s3_bucket"
                                        value={s3Bucket}
                                        placeholder="Bucket Name"

                                        onChange={(e) => { setS3Bucket(e.target.value); setShowSettingTSave(true) }}

                                      />
                                    </Form.Group>
                                    {showSettingsTSave && (<>
                                      <Button variant="secondary" onClick={() => setShowSettingTSave(false)} >
                                        Close
                                      </Button>
                                      <Button variant="primary" onClick={setTransporterSettings}>
                                        Save Changes
                                      </Button>
                                    </>)}
                                  </Col></Row>
                              ) : (
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                  <Form.Label>Transport Viewer Type</Form.Label>
                                  <Form.Control
                                    type="type"
                                    value={warehouseType}
                                    placeholder="Business Type"
                                    autoFocus
                                    onChange={(e) => { setWarehouseType(e.target.value); setShowSettingsSave(true) }}

                                  />
                                  {showSettingsSave && (<>
                                    <Button variant="secondary" onClick={handleCloseInvite} >
                                      Close
                                    </Button>
                                    <Button variant="primary" onClick={sendInvite}>
                                      Save Changes
                                    </Button>
                                  </>)}
                                </Form.Group>
                              )}
                            </>)
                          }
                        </Form>

                      </CardBody>
                    </Card>
                  </Col>
                  {/* Maps */}

                  {props.location.state.is_type === 'warehouse' && (
                    <>
                      {isLoaded ? (
                        <Col md="12">
                          <Card className="card-plain">
                            <CardHeader>
                              <CardTitle tag="h4">Business Map</CardTitle>
                              <p className="category">Warehouse Locations. &nbsp;</p>
                            </CardHeader>
                            <CardBody>
                              <Grid item lg={12} md={12} xs={12} style={{ width: "90%", minHeight: "30vh" }} container>
                                <GoogleMap
                                  mapContainerStyle={containerStyle}
                                  options={{
                                    backgroundColor: "#1F85DE",
                                    disableDoubleClickZoom: false,
                                    zoomControl: true,
                                    fullscreenControl: false,
                                    styles: exampleMapStyles,
                                    streetViewControl: false
                                  }}
                                  center={center}
                                  zoom={11}
                                  onLoad={onLoad}
                                  onUnmount={onUnmount}
                                  onClick={onClick}
                                >
                                  {/* Child components, such as markers, info windows, etc. */}
                                  <>
                                    {showMarker && (
                                      <MarkerF
                                        position={{ lat: markerLat, lng: markerLong }} />
                                    )}
                                    {/* Map over older ones */}
                                    {markers.map((mapType, index) => {
                                      console.log("Marker...", mapType)
                                      console.log(mapType.lng, mapType.lat, index)
                                      return (<MarkerF
                                        position={{ lat: mapType.lat, lng: mapType.lng }} key={index} />)
                                    })
                                    }
                                  </>
                                </GoogleMap>
                              </Grid>
                            </CardBody>
                          </Card>
                        </Col>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  {/* Licensing */}
                  <Col md="12">
                    <Card className="card-plain">
                      <CardHeader>
                        <CardTitle tag="h4">Licensing</CardTitle>
                        <p className="category">The license applied to users business.</p>
                      </CardHeader>

                      <CardBody>
                        <Row>
                          <Col lg={4} xs={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>License</Form.Label>
                              <Form.Control
                                type="license"
                                value={s3Bucket}
                                placeholder="License"

                                onChange={(e) => { setS3Bucket(e.target.value); setShowSettingTSave(true) }}

                              />
                            </Form.Group>
                          </Col>
                          <Col lg={4} xs={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>start_period</Form.Label>
                              <Form.Control
                                type="start_period"
                                value={s3Bucket}
                                placeholder=""

                                onChange={(e) => { setS3Bucket(e.target.value); setShowSettingTSave(true) }}

                              />
                            </Form.Group>
                          </Col>
                          <Col lg={4} xs={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>end_period</Form.Label>
                              <Form.Control
                                name="end_period"
                                value={s3Bucket}
                                placeholder=""

                                onChange={(e) => { setS3Bucket(e.target.value); setShowSettingTSave(true) }}

                              />
                            </Form.Group>
                          </Col>
                          <Col lg={4} xs={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>Used trial</Form.Label>
                              <Form.Control
                                name="used trial"
                                value={s3Bucket}
                                placeholder="License"

                                onChange={(e) => { setS3Bucket(e.target.value); setShowSettingTSave(true) }}

                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  {/* Users */}
                  <Col md="12">
                    <Card className="card-plain">
                      <CardHeader>
                        <CardTitle tag="h4">Users</CardTitle>
                        <p className="category">Users linked to business. &nbsp;<a onClick={() => setShowInvite(true)}><i className="tim-icons icon-simple-add" /></a></p>
                      </CardHeader>
                      <CardBody>
                        <Table className="tablesorter" responsive>
                          <thead className="text-primary">
                            <tr>
                              <th>Name</th>
                              <th>Type</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(users).map((key) => (
                              <tr key={users[key].user_id}>
                                <td>{key}</td>
                                <td>{users[key].group}</td>
                                <td>
                                  <a onClick={() => updateUser(users[key].user_id)}><i className="tim-icons icon-pencil" /></a> &nbsp;
                                  <a onClick={() => removeUser(key)}><i className="tim-icons icon-trash-simple" /></a>&nbsp;&nbsp;


                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>

                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="12">
                    <Card className="card-plain">
                      <CardHeader>
                        {
                          props.location.state.is_type === 'warehouse' ? (<>
                            <CardTitle tag="h4">Warehouses</CardTitle>
                          </>) : (<>
                            <CardTitle tag="h4">Transporters</CardTitle>
                          </>)}

                        <p className="category">linked to business. &nbsp;<a onClick={() => setShowDependent(true)}><i className="tim-icons icon-simple-add" /></a></p>
                      </CardHeader>
                      <CardBody>
                        <Table className="tablesorter" responsive>

                          {
                            props.location.state.is_type === 'warehouse' ? (<>
                              <thead className="text-primary">
                                <tr>
                                  <th>Name</th>
                                  <th>Location</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(warehouses).map((key) => (
                                  <>

                                    <tr key={key}>
                                      <td>{warehouses[key].name}</td>
                                      <td>{warehouses[key].location}</td>
                                      <td>
                                        {/* <a onClick={() => editWarehouse(key)}><i className="tim-icons icon-pencil" /></a> &nbsp; */}
                                        {/* <a onClick={() => viewWarehouse(key)}><i className="tim-icons icon-user-run" /></a>&nbsp;&nbsp; */}
                                        <a onClick={() => removeWarehouse(key)}><i className="tim-icons icon-trash-simple" /></a>&nbsp;&nbsp;


                                      </td>
                                    </tr>

                                  </>

                                ))}
                              </tbody>
                            </>) : (<>
                              {props.location.state.is_type === 'transporter' ? (
                                <> <thead className="text-primary">
                                  <tr>
                                    <th>Name</th>
                                    <th>Contact</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                  <tbody>
                                    {Object.keys(transporters).map((key) => {
                                      return (
                                        <>

                                          <tr key={key}>
                                            <td>{transporters[key].name}</td>
                                            <td>{transporters[key].contact_number}</td>
                                            <td>
                                              <a onClick={() => removeTransporter(key)}><i className="tim-icons  icon-trash-simple" /></a>&nbsp;&nbsp;
                                            </td>
                                          </tr>

                                        </>

                                      )
                                    })}
                                  </tbody></>
                              ) : (
                                <>
                                  <thead className="text-primary">
                                    <tr>
                                      <th>Name</th>
                                      <th>Contact</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Object.keys(settingTV).map((key) =>
                                      <tr key={key}>
                                        <td>{key}</td>
                                        <td>{settingTV[key].s3_path}</td>
                                        <td>
                                          <a onClick={() => removeTransporter(key)}><i className="tim-icons  icon-trash-simple" /></a>&nbsp;&nbsp;
                                        </td>
                                      </tr>


                                    )}
                                  </tbody>
                                </>
                              )

                              }
                            </>)}
                        </Table>
                      </CardBody>
                    </Card>
                  </Col>
                  {/* Tansporter Viewers */}
                  {props.location.state.is_type === 'transporter' && (
                    <Col md="12">
                      <Card className="card-plain">
                        <CardHeader>
                          <CardTitle tag="h4">Transporter Viewer</CardTitle>

                          <p className="category">linked to business. &nbsp;<a onClick={() => setshowTViewwer(true)}><i className="tim-icons icon-simple-add" /></a></p>
                        </CardHeader>
                        <CardBody>

                          <>  <Table className="tablesorter" responsive>
                            <thead className="text-primary">
                              <tr>
                                <th>Name</th>
                                <th>Path</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(transporterViewers).map((key) => {
                                return (

                                  <tr key={key}>
                                    <td>{key}</td>
                                    <td>{transporterViewers[key].s3_path}</td>
                                    <td>
                                      {/* <a onClick={() => editTransporter(key)}><i className="tim-icons icon-pencil" /></a> &nbsp; */}
                                      {/* <a onClick={() => viewTransporter(key)}><i className="tim-icons icon-user-run" /></a>&nbsp;&nbsp; */}
                                      <a onClick={() => removeTransporter(key)}><i className="tim-icons  icon-trash-simple" /></a>&nbsp;&nbsp;
                                    </td>
                                  </tr>


                                )
                              })}
                            </tbody>
                          </Table></>

                        </CardBody>
                      </Card>
                    </Col>)}
                  {/* Transactions */}
                  <Col md="12">
                    <Card className="card-plain">
                      <CardHeader>
                        <CardTitle tag="h4">Transactions</CardTitle>
                        <p className="category">Transactions linked to business.</p>
                      </CardHeader>
                      <CardBody>
                        <Table className="tablesorter" responsive>
                          <thead className="text-primary">
                            <tr>
                              <th>Name</th>
                              <th>Type</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(users).map((key) => (
                              <tr key={users[key].user_id}>
                                <td>{key}</td>
                                <td>{users[key].group}</td>
                                <td>
                                  <a onClick={() => updateUser(users[key].user_id)}><i className="tim-icons icon-pencil" /></a> &nbsp;
                                  <a onClick={() => removeUser(key)}><i className="tim-icons icon-trash-simple" /></a>&nbsp;&nbsp;


                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>

                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                {/* Invite User */}
                <Modal show={showInvite} onHide={handleClose} style={{ background: "rgba(20, 20, 20, 0.7)" }}>
                  <Modal.Header>
                    <Modal.Title>Invite User</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}>
                    <Form>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Title for the business"
                          autoFocus
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>Role</Form.Label>
                        <ToggleButtonGroup
                          value={alignment}
                          exclusive
                          onChange={handleAlignment}
                          style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white", width: "100%", justifyContent: "center" } : { backgroundColor: "white", width: "100%", justifyContent: "center" }}
                        >

                          <ToggleButton value="Viewer" aria-label="left aligned" style={(theme.theme !== 'white-content') ? { color: "white" } : {}}>
                            Viewer
                          </ToggleButton>
                          <ToggleButton value="Admins" aria-label="centered" style={(theme.theme !== 'white-content') ? { color: "white" } : {}}>
                            Admins
                          </ToggleButton>
                          <ToggleButton value="Finance" aria-label="right aligned" style={(theme.theme !== 'white-content') ? { color: "white" } : {}}>
                            Finance
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}>
                    <Button variant="secondary" onClick={handleCloseInvite} style={{ marginLeft: "20%" }}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={sendInvite} style={{ marginRight: "20%" }}>
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* Add Deps */}
                <Modal show={showDependent} onHide={handleCloseDependent} style={{ background: "rgba(20, 20, 20, 0.7)" }}>
                  <Modal.Header>
                    <Modal.Title>Add {
                      props.location.state.is_type === 'warehouse' ? (<>
                        Warehouses
                      </>) : (<>
                        Transporters
                      </>)}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}>
                    {
                      props.location.state.is_type === 'warehouse' ? (<>
                        <Form>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Warehouse Name</Form.Label>
                            <Form.Control
                              type="name"
                              placeholder="Title for the business"
                              autoFocus
                              value={warehouseName}
                              onChange={(e) => setWarehouseName(e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Contact Number</Form.Label>
                            <Form.Control
                              type="name"
                              autoFocus
                              value={contactNumber}
                              onChange={(e) => setContactNumber(e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Location</Form.Label>
                            <Form.Control
                              type="name"
                              autoFocus
                              value={location}
                              onChange={(e) => setLocation(e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Operation Email</Form.Label>
                            <Form.Control
                              type="name"
                              autoFocus
                              value={operationEmail}
                              onChange={(e) => setOperationEmail(e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email Escalation 2</Form.Label>
                            <Form.Control
                              type="emailEscalation2"
                              autoFocus
                              value={emailEscalation2}
                              onChange={(e) => setEmailEscalation2(e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Operating Hours</Form.Label>
                            <Form.Control
                              type="name"
                              autoFocus
                              value={operatingHours}
                              onChange={(e) => setOperatingHours(e.target.value)}
                            />
                          </Form.Group>

                        </Form>
                      </>) : (<>
                        <Form>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="transporterName"
                              value={transporterName}
                              autoFocus
                              onChange={(e) => setTransporterName(e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Contact Number</Form.Label>
                            <Form.Control
                              type="contact"
                              value={tContactNumber}
                              autoFocus
                              onChange={(e) => setTContactNumber(e.target.value)}
                            />
                          </Form.Group>

                        </Form>
                      </>)
                    }

                  </Modal.Body>
                  <Modal.Footer style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}>
                    <Button variant="secondary" onClick={handleCloseDependent} style={{ marginLeft: "20%" }}>
                      Close
                    </Button>
                    {props.location.state.is_type === 'warehouse' ? (
                      <Button variant="primary" onClick={addWarehouse} style={{ marginRight: "20%" }}>
                        Save Changes
                      </Button>
                    ) : (
                      <Button variant="primary" onClick={addTransporter} style={{ marginRight: "20%" }}>
                        Save Changes
                      </Button>
                    )

                    }

                  </Modal.Footer>
                </Modal>
                {/* Viewer Modal */}
                {/* Add Deps */}
                <Modal show={showTViewer} onHide={handleCloseDependent} style={{ background: "rgba(20, 20, 20, 0.7)" }}>
                  <Modal.Header>
                    <Modal.Title>Add Transporter Viewer</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}>
                    <>
                      <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Business Name</Form.Label>
                          <Form.Control
                            type="name"
                            placeholder="Title for the business"
                            autoFocus
                            value={viewerName}
                            onChange={(e) => setViewerName(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Business Identifier</Form.Label>
                          <Form.Control
                            type="name"
                            autoFocus
                            value={viewerIdentitfier}
                            onChange={(e) => setIdentityfier(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Contact Number</Form.Label>
                          <Form.Control
                            type="name"
                            autoFocus
                            value={viewerContact}
                            onChange={(e) => setViewerContact(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Admin Email</Form.Label>
                          <Form.Control
                            type="name"
                            autoFocus
                            value={viewerEmail}
                            onChange={(e) => setVieweEmail(e.target.value)}
                          />
                        </Form.Group>
                      </Form>
                    </>

                  </Modal.Body>
                  <Modal.Footer style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}>
                    <Button variant="secondary" onClick={handleCloseViewer} style={{ marginLeft: "20%" }}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={addViewer} style={{ marginRight: "20%" }}>
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            ) : (<>
              <Row >

                <Col md="12" >
                  <Card className="card-plain" style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    alignContent: 'center',
                    height: '100%',
                    width: '100%',
                  }} >
                    <CardHeader >
                      <CardTitle tag="h4">We could not find this business..</CardTitle>

                    </CardHeader>
                    <CardBody>
                      <p className="category">If this is a mistake please contact our team</p><br />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>)}

          </div>
        </>)
        : (
          <div className="content">
            <Row>

              <Col md="12">
                <Card className="card-plain">
                  <CardHeader>
                    <CardTitle tag="h4">Business Overview</CardTitle>
                  </CardHeader>
                  <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                  }}>
                    <Blocks
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>


        )
      }

    </>
  );
}

export default BusinessOverview;
