import React, { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { Blocks } from 'react-loader-spinner'
import NotificationAlert from "react-notification-alert";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "../assets/css/black-dashboard-react.css"
import { getItem, postItem, updateItem, deleteItem } from "../services/api.svs";
import { Link } from "react-router-dom"
import { ThemeContext, themes } from "../contexts/ThemeContext"
import { DataGrid } from '@mui/x-data-grid';
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";
import { Auth } from 'aws-amplify';
import BusinessOverview from './BusinessOverview';

function Licenses({ user }) {

  const theme = useContext(ThemeContext);

  const [IsLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([])
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseUpdate = () => setShowUpdate(false);
  const [showUpdate, setShowUpdate] = useState(false);

  // License form fields
  const [licenseName, setLicenseName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [validityPeriod, setValidityPeriod] = useState("");
  const [permitAll, setPermitAll] = useState(false);
  const [directInvoice, setDirectInvoice] = useState(false);
  const [trialEnabled, setTrialEnabled] = useState(false);
  const [retryTrial, setRetryTrial] = useState(false);
  const [trialPeriod, setTrialPeriod] = useState("");

  const notificationAlertRef = React.useRef(null);
  const notify = (place, message, type) => {
    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const addLicense = async () => {
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    const dataToSend = {
      license_name: licenseName,
      description: description,
      price: price,
      validity_period: validityPeriod,
      permit_all: permitAll,
      direct_invoice: directInvoice,
      trial_enabled: trialEnabled,
      retry_trial: retryTrial,
      trial_period: trialPeriod
    };
    postItem(`/admin/License`, dataToSend, token).then(resp => {
      let newArray = [...items, resp.data.data];
      setItems(newArray)
      let message = (<div>
        <div>
          Added a new License.
        </div>
      </div>);
      notify("tl", message, "success");
      setShow(false)
    }).catch(() => {
      let message = (<div>
        <div>
          Could not add new License.
        </div>
      </div>);
      notify("tl", message, "danger");
      setShow(false)
    })
    // Reset form fields after submission
    setLicenseName("");
    setDescription("");
    setPrice(0);
    setValidityPeriod("");
    setPermitAll(false);
    setDirectInvoice(false);
    setTrialEnabled(false);
    setRetryTrial(false);
    setTrialPeriod("");
  };

  useEffect(() => {
    const getBusinesss = async () => {
      const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
      getItem('/admin/license/?type=partial', token).then(resp => {
        setItems(resp.data)
        setIsLoaded(true)
      }).catch(resp => {
        setItems([])
        setIsLoaded(true)
        let message = (<div>
          <div>
            Error retrieved getting the data
          </div>
        </div>)
        notify("tl", message, "danger")
      })
      //stop loading when data is fetched
    }
    getBusinesss()
  }, [])

  const columnDefs = [
    { headerName: 'Name', field: 'license_name', flex: 1 },
    { headerName: 'Public', field: 'public_enabled', flex: 1 },
    {
      headerName: 'Actions', flex: 1, renderCell: (params) => (
        <div>
          <a href="#" onClick={() => console.log("Editing")}><i className="tim-icons icon-pencil" /></a> &nbsp;
          <Link to={{
            pathname: '/overview',
            // search: '?',
            state: params.row
          }}>
            <a href="#"><i className="tim-icons icon-user-run" /></a>&nbsp;&nbsp; </Link>
          {params.row.is_status !== "published" ? (<a href="#" onClick={() => console.log("Deactivate")}><i className="tim-icons icon-trash-simple" /></a>) : (null)}

        </div>
      ),
    }
  ];


  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {IsLoaded ? (
        <>
          <>
            <div className="content">
              <Row>
                <Col md="12">
                  <Card className="card-plain">
                    <CardHeader>
                      <CardTitle tag="h4">License</CardTitle>
                      <p className="category">Licenses for businesses. &nbsp;<a href="#" onClick={() => setShow(true)}><i className="tim-icons icon-simple-add" /></a></p>
                    </CardHeader>
                    <CardBody>
                      <DataGrid
                        sx={{
                          '& .MuiDataGrid-cell': {
                            color: theme === themes.dark ? 'white' : 'black',
                            fontWeight: 700,
                          },
                          '& .MuiDataGrid-columnHeader': {
                            color: theme === themes.dark ? 'black' : '#e5763bd9',
                            fontWeight: 700,
                          },
                        }}
                        rows={items}
                        columns={columnDefs}
                        pageSize={25}
                        rowsPerPageOptions={[25,50,75,100]}
                        getRowId={(row) => row.license_id}
                        loading={!IsLoaded}
                        autoHeight
                        checkboxSelection={false} // Change as needed
                        disableSelectionOnClick
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>

            {/* Create modal */}
            <Modal show={show} onHide={handleClose} style={{ background: "rgba(20, 20, 20, 0.7)" }}>
              <Modal.Header closeButton style={(theme.theme !== 'white-content') ? {} : { backgroundColor: "white" }}>
                <Modal.Title>Create License</Modal.Title>
              </Modal.Header>
              <Modal.Body style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}>
                <Form>
                  <Form.Group className="mb-3" controlId="licenseName">
                    <Form.Label>License Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={licenseName}
                      onChange={(e) => setLicenseName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="price">
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                      type="number"
                      value={price}
                      onChange={(e) => setPrice(parseFloat(e.target.value))}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="validityPeriod">
                    <Form.Label>Validity Period</Form.Label>
                    <Form.Control
                      type="number"
                      value={validityPeriod}
                      onChange={(e) => setValidityPeriod(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="permitAll">
                    <Form.Check
                      type="checkbox"
                      label="Permit All"
                      checked={permitAll}
                      onChange={(e) => setPermitAll(e.target.checked)}
                    />
                  </Form.Group>
                  <Form.Group controlId="permitAll">
                    <Form.Check
                      type="checkbox"
                      label="Direct Invoice"
                      checked={permitAll}
                      onChange={(e) => setPermitAll(e.target.checked)}
                    />
                  </Form.Group>
                  <Form.Group controlId="permitAll">
                    <Form.Check
                      type="checkbox"
                      label="Trial Enabled"
                      checked={permitAll}
                      onChange={(e) => setPermitAll(e.target.checked)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="validityPeriod">
                    <Form.Label>Trial Period</Form.Label>
                    <Form.Control
                      type="number"
                      value={validityPeriod}
                      onChange={(e) => setValidityPeriod(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="permitAll">
                    <Form.Check
                      type="checkbox"
                      label="Retry Trial"
                      checked={permitAll}
                      onChange={(e) => setPermitAll(e.target.checked)}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}>
                <Button variant="secondary" onClick={handleClose} style={{ marginLeft: "20%" }}>
                  Close
                </Button>
                <Button variant="primary" onClick={addLicense} style={{ marginRight: "20%" }}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
            {/* Update modal */}
            <Modal show={showUpdate} onHide={handleClose} style={{ background: "rgba(20, 20, 20, 0.7)" }}>
              <Modal.Header>
                <Modal.Title>Update License</Modal.Title>
              </Modal.Header>
              <Modal.Body style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}>
                
              </Modal.Body>
              <Modal.Footer style={(theme.theme !== 'white-content') ? { backgroundColor: "#e5763bd9", color: "white" } : { backgroundColor: "white" }}>
                <Button variant="secondary" onClick={handleCloseUpdate} style={{ marginLeft: "20%" }}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleCloseUpdate} style={{ marginLeft: "20%" }}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </>



        </>)
        : (
          <div className="content">
            <Row>

              <Col md="12">
                <Card className="card-plain">
                  <CardHeader>
                    <CardTitle tag="h4">License</CardTitle>
                  </CardHeader>
                  <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                  }}>
                    <Blocks
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>


        )
      }

    </>
  );
}

export default Licenses;
