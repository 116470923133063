import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Amplify } from 'aws-amplify';
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import '@aws-amplify/ui-react/styles.css';
import "assets/css/auth.css"
import { Auth } from 'aws-amplify';
import {AmplifyComponent} from './amplify-component'
import {GisticsApp } from './app'


Amplify.configure(awsconfig);

const root = ReactDOM.createRoot(document.getElementById("root"));

Sentry.init({
  dsn: "https://c08cea86363145b8ac27c0eee5a6d2d0@o796007.ingest.sentry.io/4503989959196672",
  integrations: [new BrowserTracing()],
  environment: process.env.STAGE,
  tracesSampleRate: 1.0,
});

export function App() {

  return ( <>
  <Authenticator components={AmplifyComponent} hideSignUp={true}>
    <GisticsApp/>
  </Authenticator>
    </>
    
  );
}

root.render(
  <App/>
);

