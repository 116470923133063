/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Banners from "views/Banners.js";
import Business from "views/Business.js";
import BusinessOverview from "views/BusinessOverview";
import ContactUs from "views/ContactUs.js";
import Health from "views/Health.js";
import Users from "views/Users.js";
import Transactions from "views/Transactions";
import Transaction from "views/Transaction";
import Cards from "views/Cards";
import LicenseComponents from "views/LicenseComponents";
import License from "views/License";
import Licenses from "views/Licenses";
import Audits from "views/Audit";
import OAuthProviders from "views/OauthProviders";

var routes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   rtlName: "Dashboard",
  //   icon: "tim-icons icon-chart-pie-36",
  //   component: Dashboard,
  //   layout: "/admin",
  //   show: true
  // },
  {
    path: "/dashboard",
    name: "Audits",
    rtlName: "Audits",
    icon: "tim-icons icon-components",
    component: Audits,
    layout: "/admin",
    show: true
  },
  {
    path: "/dashboard",
    name: "Banners",
    rtlName: "Banners",
    icon: "tim-icons icon-bulb-63",
    component: Banners,
    layout: "/admin",
    show: true
  },
  {
    path: "/business",
    name: "Business",
    rtlName: "Business",
    icon: "tim-icons icon-bank",
    component: Business,
    layout: "/admin",
    show: true
  },
  {
    path: "/overview",
    name: "Business Overview",
    rtlName: "Business Overview",
    icon: "tim-icons icon-bank",
    component: BusinessOverview,
    layout: "/admin",
    show: false
  },
   // Cards
   {
    path: "/payfast",
    name: "Cards",
    rtlName: "Cards",
    icon: "tim-icons icon-credit-card",
    component: Cards,
    layout: "/admin",
    show: true
  },
  {
    path: "/payfast/:token_id",
    name: "Card",
    rtlName: "Card",
    icon: "tim-icons icon-credit-card",
    component: Cards,
    layout: "/admin",
    show: false
  },
  {
    path: "/contact",
    name: "Conact Us",
    rtlName: "Conact Us",
    icon: "tim-icons icon-email-85",
    component: ContactUs,
    layout: "/admin",
    show: true
  },
  // Health
  {
    path: "/health",
    name: "Health",
    rtlName: "health",
    icon: "tim-icons icon-heart-2",
    component: Health,
    layout: "/admin",
    show: true
  },
  // Licenses
  {
    path: "/licenses",
    name: "Licenses",
    rtlName: "Licenses",
    icon: "tim-icons icon-key-25",
    component: Licenses,
    layout: "/admin",
    show: true
  },
  {
    path: "/licenses/:license_id",
    name: "License",
    rtlName: "License",
    icon: "tim-icons icon-key-25",
    component: License,
    layout: "/admin",
    show: false
  },
  // License components
  {
    path: "/license_components",
    name: "License Components",
    rtlName: "License Components",
    icon: "tim-icons icon-lock-circle",
    component: LicenseComponents,
    layout: "/admin",
    show: true
  },
  {
    path: "/license_components/:component_id",
    name: "License Component",
    rtlName: "License Component",
    icon: "tim-icons icon-lock-circle",
    component: LicenseComponents,
    layout: "/admin",
    show: false
  },
  // Oauth
  {
    path: "/oauth_provider",
    name: "Oauth Provider",
    rtlName: "Oauth Provider",
    icon: "tim-icons icon-controller",
    component: OAuthProviders,
    layout: "/admin",
    show: true
  },
  {
    path: "/oauth_provider/:provider_id",
    name: "Oauth Provider",
    rtlName: "Oauth Provider",
    icon: "tim-icons icon-controller",
    component: OAuthProviders,
    layout: "/admin",
    show: false
  },
  {
    path: "/oauth_provider",
    name: "Oauth Links",
    rtlName: "Oauth Links",
    icon: "tim-icons icon-link-72",
    component: OAuthProviders,
    layout: "/admin",
    show: true
  },
  {
    path: "/oauth_provider/:provider_id",
    name: "Oauth Links",
    rtlName: "Oauth Links",
    icon: "tim-icons icon-link-72",
    component: OAuthProviders,
    layout: "/admin",
    show: false
  },
  // Transactions
  {
    path: "/transactions",
    name: "Transactions",
    rtlName: "Transactions",
    icon: "tim-icons icon-bank",
    component: Transactions,
    layout: "/admin",
    show: true
  },
  {
    path: "/transactions/:transaction_id",
    name: "Transaction",
    rtlName: "Transaction",
    icon: "tim-icons icon-bankn",
    component: Transaction,
    layout: "/admin",
    show: false
  },
  // User
  {
    path: "/users",
    name: "Users",
    rtlName: "Users",
    icon: "tim-icons icon-badge",
    component: Users,
    layout: "/admin",
    show: true
  },
  {
    path: "/users/:user_id",
    name: "Users",
    rtlName: "Users",
    icon: "tim-icons icon-badge",
    component: Users,
    layout: "/admin",
    show: false
  },
  
  
];

export default routes;
